/* You can add global styles to this file, and also import other style files */
body{
    margin:0;
    padding: 0;
    height: 100%;
}

@media  (min-width:670px) {
    .stores-box{
        margin-right: 10%;
        margin-left: 10%;
    }
   
}

.stores{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

td img{
    max-width: 30%;
   max-height: 30%;
   margin: 1%;
 
}
.store-cards {
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 8px;
    min-width: 300px;
    border: 2px solid grey;
    box-shadow: 2px 2px grey;
    display: flex;
    flex-direction: column;
    margin-left: 2%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
}

@media (max-width:670px){
    table{
        width: 60%;
    }
    td, th {
        max-width:120px;
        border:2px solid;
    
    }
    h1{
        font-size: 120%;
        padding: 2%
    }
    h2{
        font-size: 100%;
        padding: 2%
    }
    label{
        font-size: 100%;
    }
    .simple-text-field{
        height: 8px;
        max-width: 90%;
    }
    .store-cards{
        max-width: 100%;
        min-width: 250px;
       margin: 10% 10% 0% 10%;
    }
    

}
.stores-box{
    height: 92vh;
    display: flex;
    flex-direction: column;
    overflow: scroll;
}
.title{
    display: flex;
    align-items: center;
}

.title button{
    margin-left: 1%;
    background-color: rgb(233, 232, 232);
}

.simple-text-field {
    margin-top: 1%;
    margin-bottom: 2%;
    width: 100%;
    padding: 1rem 1rem;
    background-color: #f2f1f8;
    font-size: 0.875rem !important;
    border-radius: 0.3rem;
    border: 1px;
  }

.form-page{
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.form-element{
    margin-inline: 3%;
}

body {
    font-family: 'Lato', sans-serif;
  }
  

input:focus, textarea:focus, select:focus{
    outline: none;
}

table {
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    // border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    width: 20%;
  }

  textarea,input  {
    font-family: 'Lato', sans-serif;
  }

  .login-info{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-right: 10%;
  }

  .error-message{
      color: red;
  }

